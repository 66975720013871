.BubblePopUp{

    .dialog {
        width: 70%;
        position: relative;
        margin: 10% auto;
        padding: 5px 5px 5px 10px;
        border-radius:10px;
        background: #9f20a1;
        color: #FFFFFF;
      }
      .invert{
        background: #ededed !important;
        color: #10009e !important;
      }
      .popup-heading{
        text-align: center;
        margin-bottom: 0rem;
    }
    .popup-content{
      text-align: center;
    }
      .close {
        background: #606061;
        color: #FFFFFF;
        line-height: 25px;
        position: absolute;
        right: -12px;
        text-align: center;
        top: -10px;
        width: 24px;
        text-decoration: none;
        font-weight: bold;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        border-radius: 12px;
    } 



}