@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat Regular"),
    url(./../fonts/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat Bold"),
    url(./../fonts/Montserrat-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Italic";
  src: local("Montserrat Italic"),
    url(./../fonts/Montserrat-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat Light"),
    url(./../fonts/Montserrat-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat Medium"),
    url(./../fonts/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Thin";
  src: local("Montserrat Thin"),
    url(./../fonts/Montserrat-Thin.ttf) format("truetype");
}

@import "./App.scss";

body {
  margin: 0;
  font-family: $global-font-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
