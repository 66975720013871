.coutry-row-wraper {
  display: flex;
  .coutry-item {
    text-align: center;
    padding: 1em;
  }
  > :last-of-type {
    margin-left: auto;
  }
}
