@import "./../../css/variables.scss";

.product-container {
  position: relative;
  top: $global-top-margin;
  padding-bottom: 18%;
  .product-brand-title {
    @include set-global-padding;
    color: $primary-purple;
    .title-bold {
      font-weight: 600;
    }
  }
  .product-plain-content {
    @include set-global-padding;
    p {
      color: $primary-grey;
    }
    ol{
      color: $primary-grey;
      .list-second{
        list-style: lower-alpha;
      }
    }

  }


  .product-spacer {
    min-height: 13em;
  }
  .product-item-container {
    background-color: $primary-purple;
    min-height: 6rem;
    .product-item {
      position: absolute;
      top: 5.5em;
      z-index: 700;
      transition: top 1s ease-in-out 0s;
    }
    .atop {
      top: 5em;
    }
  }
  .product-pie-container {
    display: flex;
    flex-direction: column;
    .no-border {
      border-bottom: 0px !important;
    }
    .product-consumer-row {
      display: flex;
      justify-content: space-between;
      padding-top: 0.5rem;
      border-bottom: 1px dotted $primary-purple;

      .product-pie {
        padding-right: 0.5rem;
        display: flex;
        align-items: center;
      }
      .product-data {
        padding-left: 0.5rem;
        color: $primary-black;
      }
    }
  }
}
