@import "./../../../css/variables.scss";

.accordion-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: $global-padding;
  padding-right: $global-padding;
  padding-top: 2%;
  background-color: $primary-purple;

  .accordion-header {
    display: flex;
    width: 100%;
    .header-icon {
      padding: 1rem 0rem;
    }
    h2 {
      padding: 1rem 0.5rem 0rem 0.5rem;
      color: $primary-white;
    }
  }
  .accordion-body {
    padding: 0rem 1rem 1rem 1rem;
    color: $primary-white;
    .abt-ing-img {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-bottom: 5px;
    }
    .no-border {
      border: 0px !important;
    }
    .pie-data-wrapper {
      border-bottom: 1px solid $primary-white;

      .pie-data-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 5px;
        border-bottom: 1px dotted $primary-white;

        .data-bloack {
          padding: 0.5rem 0rem 1rem 2rem;
          p {
            margin-bottom: 0px !important;
          }
        }
      }
    }
  }
}
