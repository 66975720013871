@import "./../../css/variables.scss";

.science-view-container {
  position: relative;
  top: $global-top-margin;
  padding-bottom: 18%;
  overflow-x: hidden;
  background-color: #F1E8E6;
  // F1E8E6
  .science-title {
    @include set-global-padding;
    color: $primary-purple;
    h2 {
      font-weight: 600;
    }
  }
}
