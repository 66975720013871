@import "./../../css/variables.scss";

.learn-container {
  position: relative;
  top: 20rem;
  padding-bottom: 18%;
  background-color: white;
  .learn-brand-title {
    @include set-global-padding;
    color: $primary-purple;
    background-color: white;
    .title-bold {
      font-size: 1.7em;
      font-weight: 600;
    }
  }
  .learn-content-container {
    @include set-global-padding;
    display: flex;
    flex-direction: column;
    .learn-desc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        padding-right: 0.5rem;
      }
      .p {
        padding-left: 0.5rem;
      }
    }
  }



}
.about-family-container {
  position: absolute;
  top: $global-top-margin;
  left: 0px;
  z-index: -200;
}