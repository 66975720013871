@import "./../../css/variables.scss";

.appBanner {
  box-sizing: border-box;
  padding: 10px $global-padding;
  position: fixed;
  top: calc(0% + 25px);
  right: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1030;
  width: 100%;
  max-width: $global-max-width;
  background-color: #fff;

  .nav-container {
    display: flex;
    justify-content: space-between;
    z-index: 1031;
  }
  .app-logo {
    position: relative;
    top: 3px;
  }
  .lang-select-container {
    position: relative;
    top: 3px;
    cursor: pointer;
    .lang-select {
      font-weight: 600;
      position: relative;
      top: 3px;
      padding-left: 3px;
      color: $primary-blue;
    }
  }
}
.modal-open .container-fluid,
.modal-open .bayer {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}
