@import "./../../../css/variables.scss";

.accordion-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: $global-padding;
  padding-right: $global-padding;
  padding-top: 2%;
  background-color: $primary-purple;
  .accordion-header {
    display: flex;
    width: 100%;
    .header-icon {
      padding: 1rem 0rem;
    }
    h2 {
      padding: 1rem 0.5rem 0rem 0.5rem;
      color: $primary-white;
    }
  }

  .accordion-body {
    padding: 0rem 1rem 1rem 1rem;
    color: $primary-white;
  }
}
