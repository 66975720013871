.sensi-wash-container{
    padding: 0px 10px 0px 0px;

 
    .sensi-wash-content{
        position: relative;
        min-height: 400px;;
    }
    .sensi-wash-content-emoli{
        position: relative;
        min-height: calc(66vw + 40px);
        //289px;
        //;
    }
    .sensi-wash-bubble{
        position: relative;
        height: 30px;
        width: 30px;

    }
    .bubble-active{
        -webkit-filter:brightness(46%) sepia(1) hue-rotate(251deg) saturate(166%) brightness(102%) contrast(.8);
        filter:brightness(46%) sepia(1) hue-rotate(251deg) saturate(166%) brightness(102%) contrast(.8);
    }

    .sensi-wash-back-top{
        position: absolute;
        min-height: 400px;
        padding-right: .5em;
        
    }
    .sensi-wash-back-bot{
        position: absolute;
        padding-right: .5em;
    }
    .bble--top-grid-pos{
        position: relative;
        top:45%;
        left:51%;
    }
    .bble--bot-grid-pos{
        position: relative;
        top:40%;
        left:45%;
    }

    .bble-emoli-grid-pos{
        position: relative;
        top:45%;
        left:50%;
    }

    .bbl-pop-pos{
        position: relative;
        top:40%;
    }
    
    .last-bubble-pad{
        margin-left: 60%;
    }

    .last-emoli-bubble-pad{
        margin-top: 30%;
        margin-left: 95%;
    }
    .bubble-row{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
        grid-gap: 6px;
    }    

}