@import "./../../css/variables.scss";
.navigation-container {
  padding-left: $global-padding;
  padding-right: $global-padding;
  position: fixed;
  max-width: $global-max-width;
  bottom: calc(0% - 51px);
  right: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1030;
  width: 100%;
  background-color: #ffffff;
  .nav-wrapper {
    display: flex;
    justify-content: space-around;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    .menu-link {
      display: flex;
      flex-direction: column;
      color: $primary-grey;
      a {
        text-decoration: none;
        color: $primary-grey;
        stroke: $primary-grey;
      }
      .active {
        color: $primary-purple !important;
        stroke: $primary-purple !important;
      }
      .link-icon {
        display: block;
        text-align: center;
      }
      .link-text {
        display: block;
        text-align: center;
        font-size: 0.8rem;
      }
    }
  }
  .bp-approval{
    font-size: 11px;
    color: $primary-purple;
    text-align: center;
  }
}
.no-code-bot{
  bottom: calc(0% - 34px) !important;
}

