@import "./../../css/variables.scss";

.about-up-curv {
  width: 100%;
  margin-bottom: -25px;
  @media only screen and (min-width: 768px) {
    margin-bottom: -2px;
  }
}
.about-down-curv {
  width: 100%;
  margin-top: -25px;
  @media only screen and (min-width: 768px) {
    margin-top: -2px;
  }
}
.about-container {
  display: flex;
  flex-direction: column;
  .about-family-container {
    position: absolute;
    top: $global-top-margin;
    left: 0px;
    z-index: -200;
  }

  .atop {
    top: 95px !important;
    @media only screen and (min-width: 768px) {
      top: 25rem !important;
      left: 3rem;
    }
  }
  .bepanthen-product {
    position: absolute;
    z-index: 1000;
    top: 100px;
    transition: top 1s ease 0s;
    @media only screen and (min-width: 768px) {
      top: 25rem;
      left: 3rem;
    }
  }
  .about-intro-section {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 18rem;
    padding-bottom: 18%;
    @media only screen and (min-width: 768px) {
      top: 36rem;
    }
    .about-intro-container {
      background-color: $primary-purple;
      .about-intro {
        padding-top: 20%;
        color: $primary-white;
        @media only screen and (min-width: 768px) {
          padding-top: 10%;
        }
      }
     
    }
    .content-container {
      padding-left: $global-padding;
      padding-right: $global-padding;
      .about-benifits {
        color: $primary-black;
        h2 {
          color: $primary-purple;
          font-weight: 600;
        }
        li{
          list-style: circle;
        }
      }
    }
  }
.product-shot{
  height: 399px;
  width: auto 135px;
}


}

.view-container {
  margin-top: 60px;
  padding-left: $global-padding;
  padding-right: $global-padding;
}
