@import "./../../css/variables.scss";

.by-btn {
  margin-top: 2%;
  background-color: $primary-purple;
  border-radius: 0px;
  border-color: $primary-purple;
  font-size: 1em;
  padding: 7px 5px 7px 5px;
}
