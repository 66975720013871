@import "./variables.scss";
@import "./custom.scss";

/* import bootstrap to set changes */

//required
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";

//optional

// @import "~bootstrap/scss/root";
// @import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/type";
// @import "~bootstrap/scss/images";
// @import "~bootstrap/scss/containers";
// @import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/buttons";

.App {
  position: relative;
  max-width: $global-max-width;
  margin: 0 auto;
}
