//view-port

$global-max-width: 800px;

// Custom fonts
$global-font-regular: "Montserrat-Regular";
$global-font-bold: "Montserrat-Bold";
$global-font-italic: "Montserrat-Italic";
$global-font-light: "Montserrat-Light";
$global-font-thin: "Montserrat-Medium";
$global-font-medium: "Montserrat-Thin";

//custom colors
$primary-blue: #0076ac;
$primary-purple: #88219f;
$primary-black: #3c414b;
$primary-white: #ffffff;
$primary-grey: #959595;

//padding spacing
$global-padding: 1.3em;
$global-top-margin: 55px;
// Bootstrap overrides
$font-family-base: $global-font-regular;

@mixin set-global-padding {
  padding-left: $global-padding;
  padding-right: $global-padding;
}
